var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [_c("h1", [_vm._v("Labels")])]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "4" } },
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Zoeken")]
              ),
              _c("b-form-input", {
                staticClass: "inline mr-4",
                attrs: { id: "text-search", debounce: "800" },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [_c("b-col", [_c("label-list", { attrs: { filter: _vm.filter } })], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }