<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Labels</h1>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Zoeken</label>
        <b-form-input id="text-search" class="inline mr-4" v-model="filter.q" debounce="800" />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <label-list :filter="filter" />
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import LabelList from '@/components/LabelList.vue'

  export default {
    name: 'LabelSearch',
    components: {
      LabelList,
    },
    data () {
      return {
        filter: { q: '' }
      }
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'labels' }, text: 'Labels'}
      ]
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>